@import './utils/font.css';
@import '~antd/dist/antd.less';
@import './theme/theme.custom.less';
@import './theme/typography.custom.less';

* {
    font-family: 'sofia-pro', sans-serif;
    color: #0f0e19;
    -webkit-overflow-scrolling: touch;
}

html {
    font-size: 16px;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
}

.ant-confirm-action {
    .ant-modal-body {
        padding: 25px;
        background: #0099da;
        border: 1px solid #ff5e58;
        border-radius: 6px;
    }
    .ant-modal-confirm-btns {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-modal-confirm-body {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        svg {
            margin-right: 0.625rem;
        }
    }
}

.ant-list-item-meta-title {
    margin-bottom: -1px;
}
@media (max-width: 576px) {
    .ant-picker-panel-container {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ant-picker-panels {
        flex-direction: column;
    }
    .ant-picker-date-panel,
    .ant-picker-content {
        width: 100% !important;
    }
}

